<template>
  <!-- 气泡框提示 -->
  <div class="prompt-bubble-box" v-if="promptBubble">
    <p>专业版可以自定义大纲哦</p>
    <el-button class="prompt-bubble-btn" icon="el-icon-close" circle  @click="promptBubbleBtn"></el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      promptBubble: true
    }
  },
  methods: {
    // 气泡框提示 隐藏
    promptBubbleBtn(){
      this.promptBubble = false
    }
  }
}
</script>
<style lang="less" scoped>
  .prompt-bubble-box{
    position: absolute;
    top: -120px;
    right: 0;
    width: 280px;
    height: 140px;
    transform: scaleX(-1);
    background: url("../../assets/images/prompt-bubble-box.png") no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
      margin: 14px 15px 0 0;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      transform: scaleX(-1);
    }
    .prompt-bubble-btn{
      position: absolute;
      right: 0;
      bottom: 0;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
</style>
