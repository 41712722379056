<template>
  <div :contenteditable="editable" :id="id" @keydown="keyDownhandler" @paste="handlePaste" @blur="blurHandler" class="outline-div-container">

  </div>
</template>

<script>
import icon_chart_active from '@/assets/images/icon-chart.png'
import icon_chart from '@/assets/images/icon-chart2.png'
import icon_table_active from '@/assets/images/icon-table.png'
import icon_table from '@/assets/images/icon-table2.png'
import icon_code_active from '@/assets/images/icon-code.png'
import icon_code from '@/assets/images/icon-code2.png'
export default {
  props: {
    editable: Boolean,
    data: Array,
    level: Number,
    id: String,
  },
  data() {
    return {
      chapters:["第一章","第二章","第三章","第四章","第五章","第六章","第七章","第八章","第九章","第十章","第十一章","第十二章","第十三章","第十四章","第十五章","第十六章","第十七章","第十八章"],
      addNodeFlag: false,
      domObserver: null,
      actionList: [
        {
          icon: icon_chart,
          activeIcon: icon_chart_active,
          type: 'chart',
          name: '图表'
        }, {
          icon: icon_table,
          type: 'table',
          name: '表格',
          activeIcon: icon_table_active,
        }, {
          icon: icon_code,
          activeIcon: icon_code_active,
          type: 'code',
          name: '代码'
        }
      ]
    }
  },
  methods: {
    renderOutline(items, lv=1, parentIndex) {
        let cls = "outline-title outline-lv"+lv;
        let content = "";
        for (let i = 0; i < items.length; i++) {
          let item = items[i];
          let chapterIndexName = this.chapters[i];
          if (lv !== 1) {
            chapterIndexName = parentIndex + "." + (i + 1);
          }
          let title = item.title;
          let children = item.children;
          let clickHanlder = ''
          let actiondiv = ''
          if (this.editable && this.level === lv) {
            cls += ' is_leaf'
            actiondiv  = `<span class="action">${this.createActionStr()}</span>`
          }
          if (this.editable) {
            clickHanlder = ' onclick="window.focus(this)"'
          }

          let temp = '<p ' + clickHanlder+  ' data-lv="'+lv+'" data-extend="" data-lvt="'+chapterIndexName+'"  class="'+cls+'">'+title+ actiondiv +'</p>'
          content = content + temp;
          if (children && children.length > 0) {
            if ( lv > 1) {
              content = content + this.renderOutline(children,lv+1, chapterIndexName )
            }else {
              content = content + this.renderOutline(children,lv+1, (i+1) )
            }
          }
        }
        // console.log(content)
        return content;
    },
    hasClass(dom, className) {
      return dom.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"))
    },
    addClass(dom, className) {
      if (!this.hasClass(dom, className)) {
        dom.className += " " + className;
      }
    },
    removeClass(dom, className) {
      const reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
      dom.className = dom.className.replace(reg, " ");
    },
    createActionDom(parent) {
      var node= document.createElement("span");
      this.addClass(node, 'action')
      node.innerHTML = this.createActionStr(parent.getAttribute('data-extend') || [])
      parent.appendChild(node);
    },
    createActionStr(attrs= []) {
      let str = ''
      this.actionList.forEach(item => {
        str += `<span onclick="window.setAttr(this, '${item.type}')" title='${item.name}' class="hide extend ${attrs.includes(item.type) ? 'active_' + item.type: ''}"><img alt="" src='${attrs.includes(item.type) ? item.activeIcon : item.icon}'/></span>`
      })
      return str
    },
    getOutlineData() {
      const doms = document.querySelectorAll(`#${this.id} p`)
      let data = []
      doms.forEach((dom) => {
        let level = dom.getAttribute('data-lv')
        if(dom.innerText.trim()) {
          if (level === '1') {
            data.push({
              title: dom.innerText,
              children: [],
              chartAndTable: []
            })
          } else if (level === '2') {
            let chartAndTable = []
            if (this.level + '' === level) {
              chartAndTable = bindExtendData(dom)
            }
            const levelOneData = data[data.length - 1]
            levelOneData.children.push({
              title: dom.innerText,
              children: [],
              chartAndTable
            })

          } else if (level === '3') {
            let chartAndTable = []
            if (this.level + '' === level) {
              chartAndTable = bindExtendData(dom)
            }
            const levelOneData = data[data.length - 1]
            const levelTwoData = levelOneData.children[levelOneData.children.length - 1]
            levelTwoData.children.push({
              title: dom.innerText,
              children: [],
              chartAndTable
            })
          }
        }
      })

      function bindExtendData (dom) {
        let attrs = dom.getAttribute('data-extend')
        let chartAndTable = []
        if (attrs.includes('chart')) {
          chartAndTable.push('flowchart')
        }
        if (attrs.includes('table')) {
          chartAndTable.push('table')
        }
        if (attrs.includes('code')) {
          chartAndTable.push('code')
        }
        return chartAndTable
      }
      return data
    },
    setSrc(dom, attr, isActive) {
      let src = ''
      if (attr === 'chart') {
        src = isActive ? icon_chart_active : icon_chart
      } else if (attr === 'table') {
        src = isActive ? icon_table_active : icon_table
      } else if (attr === 'code') {
        src = isActive ? icon_code_active : icon_code
      }
      for(let i = 0; i < dom.children.length; i++) {
        let item  = dom.children[i]
        item.src= src
      }
    },
    initDomServer() {
      let self = this
      self.domObserver = new MutationObserver((mutationsList, observer) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach((addedNode) => {
              if (addedNode.nodeName === 'P') {
                self.addNodeFlag = true
                self.addNodeAction()
                self.resetSort()
                setTimeout(() => {
                  self.addNodeFlag = false
                }, 500)
              }
            });

            mutation.removedNodes.forEach((removedNode) => {
              // console.log('removedNode', removedNode.nodeName, self.addNodeFlag)
              if (self.addNodeFlag) {
                return
              }
              // removedNode.nodeName === 'P' ||
              if (removedNode.nodeName === 'BR') {
                self.handlerNodeRemove()
                self.resetSort()
              }
            });
          }
        })
      })

      self.domObserver.observe(document.querySelector(`#${this.id}`), {
        childList: true,
        subtree: true,
        attributes: false
      })
    },
    addNodeAction() {
      if (!this.editable) {
        return
      }
      const doms = document.querySelectorAll(`#${this.id} p`)
      const activeDom = this.getActiveDom()
      let activeLvt = ''
      if (activeDom) {
        activeLvt = activeDom.getAttribute('data-lvt')
      }
      for (let i = 0; i < doms.length; i++) {
        const dom = doms[i]
        const lvt = dom.getAttribute('data-lvt')
        const lv = parseInt(dom.getAttribute('data-lv'))
        if (lvt === activeLvt && lv === this.level) {
          if(dom.innerHTML.includes(`<span style="font-size: 13px;"><br></span>`)) {
            dom.setAttribute('data-extend', '')
            dom.innerHTML = '<br>'
          }

        }
        if (lv === this.level) {
          const actionDom = dom.querySelector('.action')
          if (!actionDom) {
            this.createActionDom(dom)
          }
        }
      }
    },
    handlerNodeRemove() {
      if (!this.editable) {
        return
      }
      const parent = document.querySelector(`#${this.id}`)
      const doms = document.querySelectorAll(`#${this.id} p`)
      const activeDom = this.getActiveDom()
      let [activeLvt, activeLevel, upLevel, targetLevel] = ['', -1, 100, 100]
      if (activeDom) {
        activeLvt = activeDom.getAttribute('data-lvt')
        activeLevel= parseInt(activeDom.getAttribute('data-lv'))
      }
      for (let i = 0; i < doms.length; i++) {
        const dom = doms[i]
        const lv = parseInt(dom.getAttribute('data-lv'))
        const lvt = dom.getAttribute('data-lvt')
        if (lvt === activeLvt) {
            if(activeLevel === lv) {
              const actionDom = dom.querySelector('.action')
              if(!actionDom && lv === this.level) {
                this.createActionDom(dom)
              }
            }

            const deleteNextDom = doms[i + 1]
            if (deleteNextDom) {
              const deleteNextLevel = parseInt(deleteNextDom.getAttribute('data-lv'))
              if(deleteNextLevel === this.level && !deleteNextDom.innerText) { // 删除叶子节点
                i = i + 1
                parent.removeChild(deleteNextDom)
              } else {
                targetLevel = activeLevel + 1
                if (deleteNextLevel - activeLevel > 1) { // 存在跨级 需要将后面层级提升
                  upLevel = deleteNextLevel
                }
              }

            }
        } else {
          if (lv >= upLevel) {
            dom.setAttribute('data-lv', lv - 1)
            dom.setAttribute('data-extend', '')
            this.removeClass(dom, `outline-lv${lv}`)
            this.addClass(dom, `outline-lv${lv - 1}`)
            const actionDom = dom.querySelector('.action')
            if (actionDom) {
              actionDom.innerHTML = ''
              this.removeClass(actionDom, 'action')
            }
          } else  if (lv <= targetLevel){
            upLevel = 100
          }
        }
      }
    },
    setCursorPosition(element, dom) { // 获取光标位置
      var caretOffset = 0;
      var doc = element.ownerDocument || element.document;
      var win = doc.defaultView || doc.parentWindow;
      var sel;
      // console.log(1)
      if (typeof win.getSelection != "undefined") {//谷歌、火狐
        // console.log(2)
        sel = win.getSelection();
        if (sel.rangeCount > 0) {//选中的区域
          // console.log(3)
          const range = document.createRange();
          range.selectNodeContents(dom);
          this.addClass(dom, 'focus')
          sel.removeAllRanges();
          sel.addRange(range);
          // var textNode = range.startContainer;
          // range.setStart(textNode, 2);
          range.setStart(dom, 1);
          range.setEnd(dom, 1);
        }
      } else if ((sel = doc.selection) && sel.type != "Control") {//IE
        // console.log(4)
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
      }
      return caretOffset;
    },
    resetSort() { // 调整编号
      const doms = document.querySelectorAll(`#${this.id} p`)
      let [index1, index2, index3] = [0, 0, 0]
      doms.forEach((dom, index) => {
        let level = dom.getAttribute('data-lv')
        if (level === '1') {
          index2 = 0
          index3 = 0
          const chaptersName = this.chapters[index1++]
          dom.setAttribute('data-lvt', chaptersName)
        } else if (level === '2') {
          index3 = 0
          dom.setAttribute('data-lvt', `${index1}.${++index2}`)
        } else if (level === '3') {
          dom.setAttribute('data-lvt', `${index1}.${index2}.${++index3}`)
        }
      })
    },
    keyDownhandler(e) {
      if (document.activeElement && document.activeElement.id === this.id) {
        if (e.code === 'Tab') {
          const focusDom = this.getActiveDom()
          if (focusDom) {
            e.preventDefault()
            this.changeLevel(focusDom, e.shiftKey)
          }
        } else if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.code)) {
          setTimeout(() => {
            const actionDom = this.getActiveDom()
            window.focus(actionDom)
          }, 200);
        } else if (e.code === 'Backspace') { // 删除
          setTimeout(() => {
            const container = document.querySelector(`#${this.id}`)
            if (!container.innerText.trim()) {
              container.innerHTML = ''
            }
          }, 200);

        }
      }

    },
    changeLevel(dom, flag) { // flag true 子变父  false 父变子
      const level = parseInt(dom.getAttribute('data-lv'))
      if (flag) { // 子变父
        if (level !== 1) {
          const doms = document.querySelectorAll(`#${this.id} p`)
          let startFlg = false
          for (let i = 0; i < doms.length; i++) {
            const currentDom = doms[i]
            if (this.hasClass(currentDom, 'focus')) {
              startFlg = true
            } else {
              if (startFlg) {
                const level2 = parseInt(currentDom.getAttribute('data-lv'))
                if (level2 > level) {
                  this.removeClass(currentDom, `outline-lv${level2}`)
                  this.addClass(currentDom, `outline-lv${level2 - 1}`)
                  currentDom.setAttribute('data-lv', level2 - 1)
                  const spanDom = currentDom.querySelector('span')
                  if (spanDom) {
                    spanDom.innerHTML = ''
                    currentDom.setAttribute('data-extend', '')
                  }
                } else {
                  break
                }
              }
            }
          }
          this.removeClass(dom, `outline-lv${level}`)
          this.addClass(dom, `outline-lv${level - 1}`)
          dom.setAttribute('data-lv', level - 1)
          if (this.level === level) {
            this.removeClass(dom, `is_leaf`)
            dom.setAttribute('data-extend', '')
            const spanDom = dom.querySelector('span')
            this.removeClass(spanDom, `action`)
            spanDom && (spanDom.innerHTML = '')
          }
          this.resetSort()
        }
      } else { // 父变子
        const lvt = dom.getAttribute('data-lvt')
        if (level !== this.level && lvt !== this.chapters[0] && !lvt.endsWith('.1')) {
          const doms = document.querySelectorAll(`#${this.id} p`)
          let startFlg = false
          for (let i = 0; i < doms.length; i++) {
            const currentDom = doms[i]
            if (this.hasClass(currentDom, 'focus')) {
              startFlg = true
            } else {
              if (startFlg) {
                const level2 = parseInt(currentDom.getAttribute('data-lv'))
                if (level2 > level) {
                  const targetLevel = level2 + 1 > this.level ? this.level : level2 + 1
                  this.removeClass(currentDom, `outline-lv${level2}`)
                  this.addClass(currentDom, `outline-lv${targetLevel}`)
                  currentDom.setAttribute('data-lv', targetLevel)
                  const spanDom = currentDom.querySelector('span')
                  if (spanDom) {
                    currentDom.removeChild(spanDom)
                    currentDom.setAttribute('data-extend', '')
                  }
                  if (this.level === targetLevel) {
                    const str =  `<span class="action">${this.createActionStr()}</span>`
                    currentDom.innerHTML += str
                  }
                } else {
                  break
                }
              }
            }
          }
          this.removeClass(dom, `outline-lv${level}`)
          this.addClass(dom, `outline-lv${level + 1}`)
          dom.setAttribute('data-lv', level + 1)
          if (this.level === level + 1) { // 叶子节点
            this.addClass(dom, `is_leaf`)
            dom.setAttribute('data-extend', '')
            dom.setAttribute('onclick', 'window.focus(this)')
            const str =  `<span class="action">${this.createActionStr()}</span>`
            dom.innerHTML += str
          }
          this.resetSort()
        }
      }
    },
    blurHandler() {// 失焦
      const pDoms = document.querySelectorAll(`#${this.id} p`)
      pDoms.forEach((item) => {
        this.removeClass(item, 'focus')
      })
    },
    handlePaste(event) { // 粘贴事件
      event.preventDefault();
      let [items, self] = [event.clipboardData.items, this];
      if (items.length) {
        let item = items[0];
        if (item.type === 'text/plain') { // 文本
          item.getAsString(function (str) {
            if(str.trim()) {
              self.domObserver && self.domObserver.disconnect()
              let list = str.split('\n')
              self.createTextToOutline(list)
              self.resetSort()
              setTimeout(() => {
                self.initDomServer()
              }, 200)
            }
          });
        }
      }
    },
    insertP(str, level, referenceNode) {
      var node= document.createElement("p");
      node.setAttribute('data-lv', level)
      this.addClass(node, 'outline-title')
      this.addClass(node, `outline-lv${level}`)
      node.setAttribute('onclick', 'window.focus(this)')
      node.innerText = str.trim()
      if (level === this.level) {
        node.setAttribute('data-extend', '')
        this.addClass(node, 'is_leaf')
        this.createActionDom(node)
      }
      if(referenceNode.id === this.id) {
        referenceNode.appendChild(node)
      }else if(referenceNode.nextElementSibling) {
        referenceNode.parentNode.insertBefore(node, referenceNode.nextElementSibling);
      } else {
        referenceNode.parentNode.appendChild(node);
      }
      return node
    },
    createTextToOutline(list) { // 粘贴 文本生成提纲
      let self = this
      //let activeDom = this.getActiveDom()
      let referenceNode = document.querySelector(`#${this.id}`)
      list.forEach(item => {
        if(item.trim()) {
          const {level, title } = parseStr(item)
          referenceNode = this.insertP(title, level, referenceNode)
        }
      })

      function parseStr(text) {
        let [level, title] = [1, text]
        const arr = text.split(' ')
        if (arr.length === 1) {

        } else if (arr.length == 2) {
          let sort = arr[0]
          level = getLevel(sort)
          title = arr[1]
        } else {
          let sort = arr[0]
          title = arr.filter((item, index) => index).join('')
          level = getLevel(sort)
        }
        return {
          level,
          title
        }
      }

      function getLevel(sort) {
        if (self.chapters.includes(sort)) {
          return 1
        } else {
          let arr = sort.split('.')
          if (arr.length > 1) {
            return arr.length
          } else {
            return 1
          }
        }
      }
    },
    getActiveDom() { // 获取当前拿到光标的元素
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const activeDom = range.commonAncestorContainer
        let dom = activeDom
        while (dom.nodeName !== 'P' && dom.nodeName !== 'DIV') {
          dom = dom.parentElement
        }
        return dom
      }
      return null
    }
  },
  mounted() {
    if (this.editable) {
      let self = this
      window.focus = (ele) => {
      if (this.editable) {
        if (ele.srcElement) {
          ele = ele.srcElement
        }
        const pDoms = document.querySelectorAll(`#${this.id} p`)
        pDoms.forEach((item) => {
          self.removeClass(item, 'focus')
        })
        self.addClass(ele, 'focus')
        const actionDom = document.querySelectorAll(`#${this.id} .action`)
        actionDom.forEach((item) => {
          let extendDom = item.querySelectorAll('.extend')
          for (let i = 0; i < extendDom.length; i++) {
            let className = extendDom[i].className
            if (!className.includes('active')) {
              this.addClass(extendDom[i], 'hide')
            }
          }
        })
        if (this.hasClass(ele, 'is_leaf')) {
          for(let i = 0; i < ele.children.length; i++) {
            let extendDom = ele.children[i].querySelectorAll('.extend')
            for (let i = 0; i < extendDom.length; i++) {
              this.removeClass(extendDom[i], 'hide')
            }
          }
        }
      }
    }

    window.setAttr = (ele, attr) => {
      const p = ele.parentNode.parentNode
      let attrs = p.getAttribute('data-extend')
      if (attrs.includes(attr)) {
        attrs = attrs.replace(attr, '')
        this.removeClass(ele, 'active_' + attr)
        this.setSrc(ele, attr, false)
      } else {
        attrs += ' ' + attr
        this.addClass(ele, 'active_' + attr)
        this.setSrc(ele, attr, true)
      }
      p.setAttribute('data-extend', attrs)
    }
    window.keyup = function (e) {
      // console.log(e)
      // console.log(this)
    }
    }
  },
  watch: {
    data: {
      handler() {
        this.$nextTick(() => {
          const container = document.querySelector(`#${this.id}`)
          this.domObserver && this.domObserver.disconnect()
          container.innerHTML = ''
          let content = this.renderOutline(this.data,1);
          container.innerHTML = content
          if (this.editable) {
            setTimeout(() => {
              this.initDomServer()
            }, 200)
          }
        })
      },
      immediate: true
    }
  }
}
</script>


<style lang="less">
.outline-div-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  outline: unset;
  background: white;
  br {
    width: 0;
    height: 0;
  }
  .action {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    span {
      background: white;
      width: 20px;
      height: 20px;
      display: flex;
      border-radius: 30px;
      margin-left: 5px;
      border: 1px solid rgb(96, 98, 102);

    }
    img {
      width: 20px;
      cursor: pointer;
    }
    .active_table {
      border-color: #67c23a;
      background: #67c23a;
    }
    .active_code {
      border-color: #e6a23c;
      background: #e6a23c;
    }
    .active_chart {
      border-color: #409eff;
      background: #409eff;
    }
  }
  .hide {
    display: none !important;
  }
  .is_leaf {
    display: flex;
    position: relative;
    padding-right: 70px;
  }
  .outline-title {
      line-height: 1.6;
      font-weight: 400;
      text-align: left;
      background-color: white !important;
    }

    .outline-title.outline-lv1 {
      font-size: 14px;
      font-weight: 600;
      color: rgba(50, 55, 66, .85);
    }

    .outline-title.outline-lv2 {
      font-size: 13px;
      color: #606266;
      font-weight: 500;
      padding-left: 13px;
    }

    .outline-title.outline-lv3 {
      font-size: 12px;
      color: #606266;
      font-weight: 400;
      padding-left: 24px;
    }

    .outline-title:before {
      content: attr(data-lvt) " ";
    }

    .outline-title+.outline-title {
      margin-top: 10px;
    }
}
</style>
