<template>
<div class="cont-box">

  <div class="form-box" v-show="ruleForm.extParam.modelCode === 1">
    <!-- 气泡框提示 -->
<!--    <PromptBubble class="h5-show"></PromptBubble>-->
    <FormTitle :infoName="infoName" />
<!--    <Bubble2 class="h5-show" :contList="contList"/>-->
    <AdvertisingTitle class="h5-show" :contList="contList" v-show="ruleForm.extParam.modelCode === 1"/>

    <div class="form-t-box">
      <div class="form-t-c" v-show="ruleForm.extParam.modelCode === 2">
        <el-form :model="ruleForm" ref="ruleForm">
          <el-form-item label="" style="margin-bottom: 0">
            <el-input placeholder="输入完整论文标题（5-50字内或20个单词内），提纲搜索更准确"
                      @keyup.native.enter="thesisSearchBtn"
                      v-model="ruleForm.title"
                      class="input-with-select"
                      maxlength="50">
              <el-select v-model="questInfo.level" slot="prepend" placeholder="提纲级别" style="width: 120px" @change="changeLevel">
                <el-option label="二级大纲" :value="'二级'"></el-option>
                <el-option label="三级大纲" :value="'三级'"></el-option>
              </el-select>
              <el-button class="form-search-btn" slot="append" icon="el-icon-search"  @click="thesisSearchBtn">搜索</el-button>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="form-t-t">
        <el-radio-group v-model="ruleForm.extParam.modelCode" size="small" @change="handleChangeTabPC">
          <el-radio-button :label="1">基础信息</el-radio-button>
          <el-radio-button :label="2">编辑大纲</el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <!-- 标准版 --><!-- v-if="ruleForm.extParam.modelCode === 1" -->
    <div class="form-list" v-show="ruleForm.extParam.modelCode === 1">
      <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="请输入论文标题" class="form-item-pc-show">
          <el-input v-model="ruleForm.title" style="position: relative; z-index: 11;"
                    type="text"
                    placeholder="请输入完整的标题，标题越完整，创作内容越准确"
                    @focus="openTitleInfo"
                    maxlength="50">
            <div class="btn-i-icon"
               v-if="ruleForm.title.length > 0"
               slot="suffix"
               @click="handleIconClick">
              <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9406"
                   width="20" height="20"><path fill="#2f6bf1" d="M693.952 630.592l193.088 193.152-60.352 60.288L633.6 690.944l60.352-60.352zM614.4 72.512c12.8-4.224 29.888 4.288 29.888 17.088l34.112 226.112c4.288 4.288 4.288 8.576 4.288 12.8L819.2 486.4c8.512 8.512 8.512 25.6-8.512 29.888l-187.776 102.4c-4.224 4.224-8.512 4.224-8.512 8.512L490.688 832c-8.576 12.8-25.6 17.088-34.176 4.288l-153.6-174.976c-4.224-4.224-8.512-8.512-12.8-8.512L89.6 627.2c-12.8-4.288-21.312-17.088-17.088-29.888l89.6-196.224v-8.576l-25.6-226.112c-4.224-17.088 8.576-25.6 21.376-25.6l230.4 8.512H396.8z m-42.688 110.976l-145.024 51.2c-12.8 4.224-29.888 4.224-42.688 4.224L226.112 230.4 243.2 379.712c0 17.088 0 34.176-8.512 51.2l-51.2 123.776 119.424 12.8c25.6 0 51.2 12.8 68.288 34.112l93.888 110.912 81.024-128c8.576-17.024 21.376-29.824 38.4-38.4l119.488-64L618.688 384c-12.8-17.088-21.376-34.112-21.376-55.488l-25.6-145.024z" p-id="9407"></path>
              </svg>
              <span>优化标题</span>
            </div>
          </el-input>
          <TitleInfoBox ref="TitleInfo"
                        :title="ruleForm.title"
                        :code="ruleForm.creationDocCode"
                        @acceptTitle="acceptTitle"/>
        </el-form-item>
        <el-form-item label="请输入论文标题" class="form-item-h5-show">
          <el-input v-model="ruleForm.title"
                    type="text"
                    placeholder="请输入完整的标题，标题越完整，创作内容越准确"
                    maxlength="50">
          </el-input>
        </el-form-item>
        <el-form-item class="h5-form-item" label="请选择科目(没有所选专业请输入)" v-if="thesisInfo.subjectList">

          <div class="show-panel" @click="showPanel('startPointId')">
            <el-input
              v-model="ruleForm.extParam.subject"
              placeholder="请输入或选择科目"
            ></el-input>
            <el-cascader id="startPointId" placeholder="请选择" v-model="ruleForm.extParam.subject"
                filterable
                :options="thesisInfo.subjectList"
                :props="{label: 'name',value: 'name',children: 'children',expandTrigger:'hover'}"
                @blur="handleBlur"
                @change="handleChange"></el-cascader>
          </div>

          <div class="show-drawer">
            <el-input
              v-model="ruleForm.extParam.subject"
              placeholder="请输入科目"
              clearable
            ></el-input>
            <el-button type="text" @click="dialogDrawer">
              选择科目
              <i class="el-icon-arrow-right"></i>
            </el-button>

            <DrawerH5 ref="Drawer" @subject="refChild" />
          </div>

        </el-form-item>
        <el-form-item label="请选择学历">
          <!-- v-model="ruleForm.extParam.education" -->
          <div class="form-xl radio-pc-show">
            <div class="form-li" v-for="(item,index) in thesisInfo.education" :class="item.active === 1 ? 'active':''"
              @click="clickNav(index)">
              <div class="form-check" v-if="item.active === 1"><i class="el-icon-check"></i></div>
              <h3>{{item.label}}</h3>
              <p>{{item.tip}}</p>
            </div>
          </div>

          <el-radio-group v-model="ruleForm.extParam.education" class="radio-h5-show" @input="clickNavH5">
            <el-radio v-for="item in thesisInfo.education" :label="item.value">{{item.label}}</el-radio>
          </el-radio-group>

        </el-form-item>
        <el-form-item label="请选择字数" v-if="thesisInfo.creationWordsNum">
          <el-radio-group v-model="ruleForm.extParam.creationWordsNum" class="radio-pc-show">
            <el-radio-button v-for="item in thesisInfo.creationWordsNum" :label="item">{{item + '字左右'}}</el-radio-button>
          </el-radio-group>

          <el-radio-group v-model="ruleForm.extParam.creationWordsNum" class="radio-h5-show h5-el-radio">
            <el-radio v-for="item in thesisInfo.creationWordsNum" :label="item">{{item + '字左右'}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>

    <div class="form-btn" v-if="ruleForm.extParam.modelCode === 1">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <div class="form-btn btn-h5-show" v-else>
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <NoticeWrapper />

  </div>

  <div class="form-right-list"
       v-loading="formLoading"
       element-loading-text="AI正在思考中..."
       v-show="ruleForm.extParam.modelCode === 2">
    <!-- 专业版 -->
    <div class="form-list2"
         v-show="ruleForm.extParam.modelCode === 2">
      <div class="form-list2-head">
        <div class="head-back" @click="headBack">
          <i class="el-icon-arrow-left"></i>
          <span>返回</span>
        </div>
        <div class="head-title">毕业论文·专业版</div>
      </div>
      <!-- 左边编辑区 -->
      <div class="list2-left">
        <div class="list2-head">
          <div class="list2-head-label"> 提纲编辑区：</div>
          <div style="font-size: 12px;">
            <span class="code">Tab</span>&nbsp;切换为子级提纲，<span class="code">Shift</span>&nbsp; <span
            class="code">Tab</span>&nbsp;切换回父级提纲
          </div>
        </div>
        <div class="list2-editor-box">
          <Outline :editable="true" :id="'outline-div'" :data="outline" ref="outlineRef" :level="level"/>
        </div>
      </div>
      <div class="list2-btn">
        <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
        <el-button style="width: 120px" size="small" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
      </div>
    </div>
    <div class="form-list2-right">
      <div class="form-t-c">
        <el-form class="form-search-input" :model="ruleForm" ref="ruleForm">
          <el-form-item label="选择科目:" class="show-panel2-box" v-if="thesisInfo.subjectList">

            <div class="show-panel2" @click="showPanel('startPointId2')">
              <el-input
                v-model="ruleForm.extParam.subject"
                placeholder="请输入或选择科目"
              ></el-input>
              <el-cascader id="startPointId2" placeholder="请选择" v-model="ruleForm.extParam.subject"
                           filterable
                           :options="thesisInfo.subjectList"
                           :props="{label: 'name',value: 'name',children: 'children',expandTrigger:'hover'}"
                           @blur="handleBlur"
                           @change="handleChange"></el-cascader>
            </div>

          </el-form-item>
          <el-form-item style="flex: 1;margin-bottom: 0;margin-left: 20px;">
            <el-input placeholder="输入完整论文标题（5-50字内或20个单词内），提纲搜索更准确"
                      @keyup.native.enter="thesisSearchBtn"
                      v-model="ruleForm.title"
                      class="input-with-select"
                      maxlength="50">
              <el-select v-model="questInfo.level" slot="prepend" placeholder="提纲级别" style="width: 120px" @change="changeLevel">
                <el-option label="二级大纲" :value="'二级'"></el-option>
                <el-option label="三级大纲" :value="'三级'"></el-option>
              </el-select>
              <el-button class="form-search-btn" slot="append" icon="el-icon-search"  @click="thesisSearchBtn">搜索</el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <!-- 刷新 -->
        <div class="tips" @click="thesisSearchBtn">
          <i class="el-icon-refresh"></i>
          <span>重新创作</span>
        </div>
      </div>

      <!-- 右边引导区 -->
      <div class="list2-right" v-if="showContent">
        <div class="thesis-guide-bg">
          <div class="thesis-guide-title">在上方搜索提纲；或直接<span>粘贴心仪提纲</span></div>
          <div class="thesis-guide-content" v-for="item in textList">
            <h5>{{item.title}}</h5>
            <p>{{item.content}}</p>
          </div>
        </div>
      </div>

      <!-- 右边操作区 -->
      <div class="list2-right" v-else>
        <div class="r-lis" v-for="(item,index) in outlineList" :key="'copy'+index">
          <div class="main-item">
            <Outline :editable="false" :data="item" :id="'outline-div-' + index" :level="level"/>
          </div>
          <div class="list2-btn">
            <el-button :class="'copy'+index" type="primary" size="mini" :data-clipboard-text="finalText" @click="copyToClipboard(item,index)">复制</el-button>
            <el-button type="success" size="mini" @click="quoteBtn(item)">引用</el-button>
          </div>
        </div>
      </div>
    </div>

  </div>

<!--  移动端 大纲 -->
  <div class="show-box-h5" v-show="ruleForm.extParam.modelCode === 2">
    <FormTitle :infoName="infoName" />
    <div class="form-t-box">
      <div class="form-t-c">
      </div>
      <div class="form-t-t">
        <el-radio-group v-model="ruleForm.extParam.modelCode" size="small" @change="handleChangeTab">
          <el-radio-button :label="1">基础信息</el-radio-button>
          <el-radio-button :label="2">编辑大纲</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="form-list2-h5" v-show="ruleForm.extParam.modelCode === 2">
      <div class="tips">请前往PC端编辑大纲</div>
    </div>
  </div>
</div>
</template>

<script>
  import ThesisApi from '@/api/thesis'
  import CommonApi from "@/api/common"
  import PromptBubble from "@/components/promptBubble/promptBubble.vue"
  import Bubble2 from "@/components/promptBubble/bubble2.vue"
  import Outline from './outline.vue'
  import { orderMixin } from '@/utils/orderMixin'
  import { validateTitle } from '@/utils/validate'
  import Clipboard from 'clipboard'
  import DrawerH5 from '@/components/drawerH5/drawerH5.vue'
  import FormTitle from "@/components/FormTitle/index.vue"
  import AdvertisingTitle from "@/components/AdvertisingTitle/index.vue"
  import NoticeWrapper from "@/components/NoticeWrapper"
  import TitleInfoBox from "@/components/titleInfoBox/index.vue"

  export default {
    mixins: [orderMixin],
    props: ['infoName','contList'],
    components: {
      FormTitle,
      Outline,
      PromptBubble,
      Bubble2,
      DrawerH5,
      AdvertisingTitle,
      NoticeWrapper,
      TitleInfoBox
    },
    data() {
      return {
        ruleForm: {
          title: '',
          creationDocId: 1,
          creationDocCode: 'THESIS',
          channelCode: null,
          extParam: {
            version: 1,
            modelCode: 1,
            education: null,
            creationWordsNum: '',
            subject: ''
          }
        },
        // rules: {
        //   title: [
        //     { required: true, message: '请输入论文标题', trigger: 'blur' },
        //     { validator: validateTitle, trigger: 'blur' }
        //   ],
        //   extParam: {
        //     subject: [
        //       { required: true, message: '请输入或选择科目', trigger: 'change' }
        //     ],
        //     education: [
        //       { required: true, message: '请选择学历', trigger: 'change' }
        //     ],
        //     creationWordsNum: [
        //       { required: true, message: '请选择字数', trigger: 'change' }
        //     ]
        //   }
        // },
        btnInfo: {
          title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
          checked: true
        },
        thesisInfo: {
          education: '',
          creationWordsNum: '',
          subjectList: ''
        },
        questInfo: {
          education: '', // 学历
          level: '二级', // 提纲级别*
          sign: '', // 签名*
          subject: '', // 科目*
          timestamp: '', // 时间戳
          title: '', // 标题*
          wordsNum: '', // 字数
        },
        textList: [{
          title: '智能化快速检索',
          content: '即时获取您所需的提纲'
        },{
          title: '丰富的提纲库',
          content: '探索各类领域研究'
        },{
          title: '个性化范文定制',
          content: '打造专属于您的文章'
        }],
        btnUnitIcon:[{
          title: '添加图表',
          name: '图表',
          img: require('@/assets/images/icon/icon-chart.png'),
          img2: require('@/assets/images/icon/icon-chart2.png'),
          type: 'primary',
          checked: false
        },{
          title: '添加表格',
          name: '表格',
          img: require('@/assets/images/icon/icon-table.png'),
          img2: require('@/assets/images/icon/icon-table2.png'),
          type: 'success',
          checked: false
        },{
          title: '添加代码',
          name: '代码',
          img: require('@/assets/images/icon/icon-code.png'),
          img2: require('@/assets/images/icon/icon-code2.png'),
          type: 'warning',
          checked: false
        }],
        showContent: true,
        showChildNode: false,
        dynamicTags: [],
        paramsData: '', // 储存跳页面的传值
        outline: [],
        outlineList: [],
        finalText: '',
        formLoading: false, //AI搜索加载
        shake: false,
      }
    },
    created() {
      this.getEducation()
      this.getSubject()
      // fetch('/outline.json').then(response => response.json()).then((json) => {
      //   this.outline = json.data[0]
      //   this.outlineList = json.data
      // })
    },
    methods: {
      // 复制
      copyToClipboard(val,index) {
        this.finalText = ''; // 重置finalText，防止多次点击累加
        this.generateText(val);
        let clipboard = new Clipboard( '.copy'+index)
        clipboard.on('success', (e) => {
          // console.log('复制成功', e)
          // 释放内存
          clipboard.destroy()
          this.$message.success('复制成功')
        })
        clipboard.on('error', (e) => {
          // 不支持复制
          // console.log('该浏览器不支持自动复制', e)
          // 释放内存
          clipboard.destroy()
        })
      },
      generateText(items, level = 1, parentIndex) {
        items.forEach((item, index) => {
          let chapterIndexName = '';
          let title = item.title;
          let children = item.children;
          if (level !== 1) {
            chapterIndexName = `${parentIndex}.${index + 1}`;
          }else {
            chapterIndexName = `第${this.toChineseNumber(index + 1)}章`;
          }
          let temp = chapterIndexName
          this.finalText += temp + ' ' + title + '\n';
          if (children && children.length > 0) {
            if ( level > 1) {
              this.generateText(children, level+1, chapterIndexName )
            }else {
              this.generateText(children, level+1, (index+1) )
            }
          }
        });
      },
      toChineseNumber(num) {
        const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        const units = ['', '十', '百', '千', '万'];
        let str = '';
        num = num.toString();
        for (let i = 0; i < num.length; i++) {
            str += chineseNumbers[num[i]] + (units[num.length - i - 1] || '');
        }
        return str;
      },
      // 引用
      quoteBtn(item){
        this.outline = [...item]
      },
      // 搜索按钮 - 搜索到结果切换右边显示2
      thesisSearchBtn(){
        if(this.ruleForm.title === ''){
          return this.$message({
            message: '请输入论文标题',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }else if(this.ruleForm.title.length < 5){
          return this.$message({
            message: '标题长度需要至少5个字!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }
        if(this.shake === true){
          return this.$message.success('请AI一点时间...');
        }
        this.shake = true
        // this.showContent = false
        this.questInfo.education = this.ruleForm.extParam.education
        this.questInfo.subject = this.ruleForm.extParam.subject
        this.questInfo.title = this.ruleForm.title
        this.questInfo.wordsNum = this.ruleForm.extParam.creationWordsNum

        let time = new Date().getTime();
        let setOf = this.ruleForm.title + this.ruleForm.extParam.subject + time + this.ruleForm.extParam.education + this.questInfo.level + time + time + this.ruleForm.extParam.creationWordsNum;
        let infoMd5 = this.$md5(setOf)
        this.questInfo.sign = infoMd5
        this.questInfo.timestamp = time

        //  查询 - AI
        this.createOutline(this.questInfo)
        this.formLoading = true
      },

      // 获取ai接口
      createOutline(data) {
        let time = new Date().getTime()
        let key = localStorage.getItem('key')
        let ticket = localStorage.getItem('ticket')
        let setOf = ticket + time + ticket + ticket + time + ticket
        let infoMd5 = this.$md5(setOf)
        ThesisApi.createOutline(data,key,time,infoMd5).then(res => {
          this.outlineList = res.data;
          // 关闭加载
          this.formLoading = false
          // 获取成功打开右侧列表
          this.showContent = false
          // 关闭防抖
          this.shake = false
        }).catch(() => {
          // 关闭加载
          this.formLoading = false
          // 关闭防抖
          this.shake = false
        })
      },
      changeLevel(value){
        this.questInfo.level = value
      },
      // 监听选择科目
      handleChange(value) {
        this.ruleForm.extParam.subject = value[1]
      },
      // handleRegionChange(value){
      //   this.$emit('provincesValueChang',value)
      // },
      // 监听标准版/专业版切换
      handleChangeTabPC(value){
        if(value === 1){
          // 显示引导
          this.showContent = true
          // 清空搜索
          // this.ruleForm.title = ''
        }else {
          this.$emit('thesisTimeLine',false)
        }
      },
      handleChangeTab(value){
        if(value === 1){
          // 显示引导
          this.showContent = true
          // 清空搜索
          // this.ruleForm.title = ''
        }
      },
      headBack(){
        this.$emit('thesisTimeLine',true)
        this.ruleForm.extParam.modelCode = 1
        this.showContent = true
      },
      // 选择学历
      clickNav(index) {
        // this.ruleForm.extParam.creationWordsNum = ''
        // this.thesisInfo.creationWordsNum = ''
        this.thesisInfo.education.forEach(item => {
          item.active = 0
        })
        this.thesisInfo.education[index].active = 1
        // this.ruleForm.extParam.education = this.thesisInfo.education[index].value
        let num = this.thesisInfo.education[index].value
        this.getWordsNum(num)
      },
      // 选择学历 移动端
      clickNavH5(val){
        // this.ruleForm.extParam.creationWordsNum = ''
        // this.thesisInfo.creationWordsNum = ''
        this.getWordsNum(val)
      },
      // 获取学历接口
      getEducation() {
        ThesisApi.getEducation().then(res => {
          let dataList = res.data
          dataList.forEach(item =>{
            item.active = 0
          })
          if(dataList.length >= 3){
            dataList[0].tip = '清晰架构，内容丰富的基础设计，确保每处细节与数据都精准无误。'
            dataList[1].tip = '强化逻辑思维，深挖理论精髓，广纳多元主题，达成高水平分析力。'
            dataList[2].tip = '潜心研究，力求创新，深挖精髓，打造原创性高、研究价值深厚的文章。'
          }
          this.thesisInfo.education = dataList;
          // 触发默认选中学历
          this.clickNav(1)
        }).catch(() => {
        })
      },
      // 获取科目
      getSubject() {
        CommonApi.getSubject().then(res => {
          this.thesisInfo.subjectList = res.data;
          // 默认选中的科目
          // this.ruleForm.extParam.subject = '计算机科学与技术类'
        }).catch(() => {
        })
      },
      // 获取字数
      getWordsNum(num) {
        ThesisApi.getWordsNum(num).then(res => {
          this.thesisInfo.creationWordsNum = res.data;
          // 保持数据一致
          // 默认选中项 num - 1
          this.ruleForm.extParam.education = this.thesisInfo.education[Number(num - 1)].value
          // 默认选中第2项 index = 1
          this.ruleForm.extParam.creationWordsNum = this.thesisInfo.creationWordsNum[1]
        }).catch(() => {
        })
      },
      submitForm(formName) {
        let _this = this
        if(_this.ruleForm.title === ''){
          return _this.$message({
            message: '请输入论文标题',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }else if(_this.ruleForm.title.length < 5){
          return _this.$message({
            message: '标题长度需要至少5个字!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }
        if(_this.ruleForm.extParam.subject === ''){
          return _this.$message({
            message: '请输入或选择科目!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }
        if(_this.ruleForm.extParam.creationWordsNum === ''){
          return _this.$message({
            message: '请选择字数!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }
        // console.log(_this.$refs.outlineRef)
        if(_this.$refs.outlineRef){
          let outlineData = _this.$refs.outlineRef.getOutlineData()
          // console.log('outlineData', outlineData)
          _this.ruleForm.extParam.outline = JSON.stringify(outlineData)
        }
        // 标准版 不提交 大纲
        if(_this.ruleForm.extParam.modelCode === 1){
          _this.ruleForm.extParam.outline = ''
        }
        let data = JSON.parse(JSON.stringify(_this.ruleForm))
        let info = JSON.stringify(data.extParam)
        data.extParam = info
        // console.log(data,_this.ruleForm)
        //生成订单 mixin
        _this.createOrder(data,data)

        // _this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     let data = JSON.parse(JSON.stringify(_this.ruleForm))
        //     let info = JSON.stringify(data.extParam)
        //     data.extParam = info
        //     // console.log(data,_this.ruleForm)
        //     //生成订单 mixin
        //     _this.createOrder(data,data)
        //   } else {
        //     return false;
        //   }
        // });
      },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // },
      // 打开隐藏的 科目选择器
      showPanel(val){
        document.getElementById(val).click()
      },
      handleBlur(e){
        // console.log(e.target.value)
        this.ruleForm.extParam.subject = e.target.value
      },
      // 移动端 选择科目 组件相关
      dialogDrawer(){
        let params = {
          show: true,
          info: this.thesisInfo
        }
        this.$refs.Drawer.showDrawer(params)
      },
      refChild(name){
        this.ruleForm.extParam.subject = name
      },
      querySearchAsync(queryString, cb) {
        console.log(queryString, cb)
        // var restaurants = this.restaurants;
        // var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
        //
        // clearTimeout(this.timeout);
        // this.timeout = setTimeout(() => {
        //   cb(results);
        // }, 3000 * Math.random());
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      handleSelect(item) {
        console.log(item);
      },
      openTitleInfo(){
        this.$refs.TitleInfo.openItme()
      },
      acceptTitle(item){
        this.ruleForm.title = item
      },
      handleIconClick(ev) {
        // console.log(ev);
        this.$refs.TitleInfo.optimize()
      }
    },
    computed: {
      level() {
        return this.questInfo.level === '二级' ? 2 : 3
      }
    }
  }
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
//.cont-box{
//  display: flex;
//  flex-direction: column;
//  align-items: flex-start;
//}
.form-item-pc-show{
  display: block;
}
.form-item-h5-show{
  display: none;
}

.btn-i-icon{
  height: 30px;
  padding: 0 8px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #e7f3ff;
  color: #2f6bf1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  span{
    margin-left: 5px;
    font-weight: bold;
  }
}
.btn-i-icon:hover{
  background-color: #d8e3f8;
}
    .form-t-box{
      width: 80%;
      max-width: 1000px;
      margin: 0 auto;
      padding: 10px 0 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .form-t-c {
        width: 60%;
        .form-search-btn{
          border-radius: 0;
          color: #ffffff;
          background-color: #409EFF;
        }
      }
      .form-t-t {
        margin-left: auto;
      }
    }

.form-right-list{
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  .form-list2 {
    position: relative;
    width: 346px;
    height: calc(100% - 20px);
    margin: 10px;
    border-radius: 15px;
    border: 2px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    //padding: 0 20px;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 85, 255, 0.15);
    .form-list2-head{
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      min-height: 48px;
      background: rgba(122, 131, 244, .16);
      font-size: 14px;
      color: #515864;
      .head-back{
        position: absolute;
        left: 10px;
        padding: 5px 10px 5px 5px;
        color: #ffffff;
        border-radius: 5px;
        background-color: #6e83f7;
        cursor: pointer;
        span{
          font-size: 12px;
        }
      }
      .head-title{
        font-weight: 600;
        font-size: 16px;
        color: #323742;
      }
    }
    .list2-left {
      width: 300px;
      height: calc(100% - 120px);
      margin: 0 auto;
      .list2-head {
        margin-top: 18px;
        margin-bottom: 13px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        font-size: 12px;
        color: #8b9098;
        text-align: left;
        .list2-head-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
        }
        .code {
          padding: 1px 4px;
          background: #f0f1f2;
          border-radius: 4px 4px 4px 4px;
          font-size: 12px;
        }
      }
      .list2-editor-box {
        position: relative;
        height: calc(100% - 86px);
        display: flex;
        flex-direction: column;
        padding: 12px 0 12px 12px;
        border: 1px dashed #e4e5e7;
        border-radius: 8px;
        background-color: #ffffff;
        transition: border .2s;
      }
    }
    .list2-btn{
      width: 90%;
      margin: 0 auto;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .form-check{
        margin-right: auto;
        margin-bottom: 6px;
        /deep/ .el-checkbox__label{
          font-size: 12px !important;
          color: #9ea2a9 !important;
        }
      }
    }
  }
  .form-list2-right{
    position: relative;
    height: 100%;
    width: calc(100% - 366px);
    padding: 32px;
    .form-t-c {
      width: 80%;
      min-width: 750px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      .form-search-input{
        width: 100%;
        margin-right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .form-search-btn{
          border-radius: 0;
          color: #ffffff;
          background-color: #409EFF;
        }
        .show-panel2-box{
          width: 246px;
          margin-bottom: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          .show-panel2{
            position: relative;
            width: 100%;
            max-width: 170px;
            #startPointId2 {
              position: absolute;
              left: 0;
              opacity: 0;
              z-index: -1;
            }
          }
        }
      }
      .tips {
        width: 100px;
        height: 30px;
        background-color: #ffffff;
        border-radius: 30px;
        //box-shadow: 0 0 5px rgba(0, 85, 255, 0.1);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .el-icon-refresh {
          font-size: 22px;
          color: #3575fd;
        }
        span {
          margin-left: 6px;
          font-size: 12px;
          color: #3575fd;
        }
      }
    }
    .list2-right {
      height: calc(100% - 9px);
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      .thesis-guide-bg{
        width: 350px;
        height: 450px;
        margin: 100px auto 0 auto;
        background: url('../../assets/images/thesisTip.png') no-repeat center 0;
        .thesis-guide-title{
          padding: 64px 0 66px 0;
          text-align: center;
          span{
            margin-left: 5px;
            color: #409EFF;
          }
        }
        .thesis-guide-content{
          height: 49px;
          margin: 40px 0 0 130px;
          h5{
            margin-bottom: 5px;
            font-size: 16px;
            font-weight: bold;
            color: #3c3c3c;
          }
          p{
            font-size: 14px;
            color: #9c9c9c;
          }
        }
      }
      .r-lis{
        width: 340px;
        height: 100%;
        margin-left: 30px;
        border-radius: 15px;
        border: 2px solid #ffffff;
        background-color: rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 15px rgba(0, 85, 255, 0.15);
        display: flex;
        flex-direction: column;
        .main-item {
          width: 100%;
          height: calc(100% - 45px);
          padding: 20px 0 20px 20px;
          display: flex;
          flex-direction: column;
          //background: #f7f7f7;
          border-radius: 12px;
        }
        .list2-btn{
          width: 90%;
          margin: 0 auto;
          padding-top: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}
.show-box-h5{
  display: none;
}
.form-list2-h5{
  display: none;
}

  @media screen and (max-width: 900px) {
    .show-box-h5{
      display: block;
    }
    .form-item-pc-show{
      display: none;
    }
    .form-item-h5-show{
      display: block;
    }
    .form-t-box{
      width: 100%;
      padding: 10px 0;
      .form-t-t{
        margin-top: 0;
      }
      .form-t-c{
        display: none;
      }
    }
    .form-right-list{
      display: none;
    }
    .form-list2-h5{
      display: block;
      width: 100%;
      padding: 15px;
      margin-top: 10px;
      background-color: rgba(255,255,255,0.7);
      box-shadow: 0 2px 5px rgba(0, 85, 255, 0.1);
      border-radius: 10px;
      .tips{
        text-align: center;
        height: 300px;
        line-height: 300px;
      }
    }
    .btn-h5-show{
      display: none;
    }
  }
</style>
