<template>
  <div class="scroll-left-box">
<!--    <div class="scroll-top-title">{{ infoName }}</div>-->
    <div class="scroll-top-box">
<!--      <div class="scroll-top-bg">你负责写标题,我来给你提供思路</div>-->
      <ul class="scroll-tip" v-if="contList && contList.length">
        <li v-for="(item,index) in contList"><span>{{ index + 1 }}</span>{{item.txt}}</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: ['infoName','contList'],
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>
<style scoped lang="less">
//.scroll-left-box{
//  //position: absolute;
//  //top: 60px;
//  //left: 0;
//  width: 350px;
//  min-width: 350px;
//  height: calc(100% - 60px);
//  //margin: -20px 0 0 -20px;
//  padding: 20px;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  //background-color: #fff5e5;
//  .scroll-top-title{
//    margin-top: 40px;
//    margin-bottom: 30px;
//    text-align: center;
//    font-size: 28px;
//    color: #fe9b59;
//  }
//  .scroll-top-box{
//    width: 100%;
//    border-radius: 15px;
//    margin-bottom: 30px;
//    background-color: #ffffff;
//    box-shadow: 0 0 15px rgba(255,65,0, 0.15);
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    justify-content: flex-start;
//    .scroll-top-bg{
//      width: 70%;
//      height: 30px;
//      line-height: 30px;
//      color: #ffffff;
//      text-align: center;
//      border-radius: 0 0 15px 15px;
//      background: linear-gradient(180deg, #ffc256, #fe9b59);
//    }
//    .scroll-tip{
//      flex: 1;
//      padding: 20px 20px 0 20px;
//      border-radius: 15px;
//      display: flex;
//      flex-direction: column;
//      align-items: center;
//      justify-content: space-between;
//      flex-wrap: wrap;
//      li{
//        position: relative;
//        width: 100%;
//        line-height: 20px;
//        padding-left: 24px;
//        margin-bottom: 20px;
//        span{
//          position: absolute;
//          left: 0;
//          top: 2px;
//          display: block;
//          width: 16px;
//          height: 16px;
//          line-height: 16px;
//          text-align: center;
//          font-size: 10px;
//          color: #ffffff;
//          background: linear-gradient(90deg, #ffc256, #fe9b59);
//          border-radius: 5px;
//        }
//      }
//    }
//  }
//}

.scroll-left-box{
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .scroll-top-box{
    width: 100%;
    padding: 10px 0;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(255,65,0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    //.scroll-top-bg{
    //  width: 40%;
    //  height: 40px;
    //  margin-top: -20px;
    //  line-height: 40px;
    //  font-size: 16px;
    //  color: #ffffff;
    //  text-align: center;
    //  border-radius: 40px;
    //  background: linear-gradient(180deg, #ffc256, #fe9b59);
    //  box-shadow: 0 0 15px rgba(255,65,0, 0.4);
    //}
    .scroll-tip{
      flex: 1;
      padding: 10px 20px 0 20px;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      li{
        position: relative;
        width: 40%;
        line-height: 20px;
        padding-left: 24px;
        margin-bottom: 10px;
        color: #e31c1c;
        font-weight: bold;
        span{
          position: absolute;
          left: 0;
          top: 2px;
          display: block;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          font-size: 10px;
          color: #ffffff;
          background: linear-gradient(90deg, #ffc256, #fe9b59);
          border-radius: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .scroll-left-box{
    display: none;
  }
}
</style>
