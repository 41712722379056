<template>
  <!-- 气泡框提示 -->
  <div class="prompt-bubble-box" v-if="promptBubble">
    <div class="scroll-box">
      <p>你负责写标题,我来给你提供思路</p>
      <ul class="scroll-lis" v-if="contList && contList.length">
        <li v-for="(item,index) in contList"><span>{{ index + 1 }}</span>{{item.txt}}</li>
      </ul>
    </div>
    <el-button class="prompt-bubble-btn" icon="el-icon-close" circle  @click="promptBubbleBtn"></el-button>
  </div>
</template>
<script>
export default {
  props: ['contList'],
  data() {
    return {
      promptBubble: true
    }
  },
  methods: {
    // 气泡框提示 隐藏
    promptBubbleBtn(){
      this.promptBubble = false
    }
  }
}
</script>
<style lang="less" scoped>
  .prompt-bubble-box{
    position: absolute;
    z-index: 111;
    top: 20%;
    right: 50%;
    width: 330px;
    height: 480px;
    margin-right: 30%;
    transform: scaleX(-1);
    background: url("../../assets/images/bubble-box2.png") no-repeat;
    background-size: 330px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .scroll-box{
      padding-top: 90px;
      transform: scaleX(-1);
      p{
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #3c3c3c;
      }
      .scroll-lis{
        width: 100%;
        border-radius: 15px;
        padding: 15px 60px 0 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        li{
          position: relative;
          width: 100%;
          border-bottom: 2px dashed #e6a23c;
          line-height: 20px;
          padding-left: 24px;
          margin-bottom: 10px;
          span{
            position: absolute;
            left: 0;
            top: 2px;
            display: block;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            font-size: 10px;
            color: #ffffff;
            background: linear-gradient(90deg, #ffc256, #fe9b59);
            border-radius: 5px;
          }
        }
      }
    }
    .prompt-bubble-btn{
      position: absolute;
      right: 50%;
      bottom: -30px;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
</style>
