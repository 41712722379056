import request from '@/utils/request'

export default {
  // 生成提纲
  createOutline(data,key,time,md5){
    return request({
      url: '/thesis/createOutline',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  // 获取学历接口
  getEducation(){
    return request({
      url: '/thesis/getEducation',
      method: 'get'
    })
  },
  // 获取字数
  getWordsNum(education){
    return request({
      url: '/thesis/getWordsNum/' + education,
      method: 'get'
    })
  }
}
